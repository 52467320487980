<template>
  <div>
    <b-row>
           <!-- <b-col cols="12" class="mb-2">-->
      <!--        <b-button class="float-right" size="sm" variant="primary" @click="sendInvitation()">-->
      <!--          <feather-icon class="mr-50" icon="SendIcon"/>-->
      <!--          <span>Send Invitation</span>-->
      <!--        </b-button>-->
      <!--      </b-col> -->
      <b-col class="mb-2" cols="12" md="4">
        <b-input-group>
          <b-input-group-prepend is-text>

            <feather-icon icon="SearchIcon"/>
          </b-input-group-prepend>

          <b-form-input v-model="filters['email']"
                        placeholder="Email"/>
        </b-input-group>
      </b-col>
      <b-col cols="12" md="3">

      </b-col>

      <b-col cols="12" md="5">
        <b-form-group class="">
          <b-button
              class="btn-sm-block  float-right ml-1 mr-1 mb-1  text-white"
              variant="dark"
              @click="callparentfunction()"

          >
            <span class="align-middle">Invite Staff</span>
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-table
              ref="table"
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="getInvitations"
              :per-page="pagination.perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="mobile_table_css"
              hover
              responsive
          >
            <template #cell(full_name)="data">
            <ul class="list-inline mb-0">
<!--              <li class="list-inline-item">
                <b-avatar :src="require('@/assets/images/avatars/1.png')" class="pull-up "/>
              </li>-->
              <li class="list-inline-item"> {{ data.item.full_name }}</li>
              <b-badge v-if="data.item.is_locum" class="ml-1" style="padding:8px" variant="light-info">
                <span class="text-dark font-weight-700"> L </span>
              </b-badge>
            </ul>
            </template>
            <!-- Name $ Avatar -->

            <!--          <template #cell(name)="data">-->
            <!--            <ul class="list-inline mb-0">-->
            <!--              <li class="list-inline-item">-->
            <!--                <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up "/>-->
            <!--              </li>-->
            <!--              <li class="list-inline-item"> {{ data.item.name }}</li>-->
            <!--              <b-badge class="ml-1" style="padding:8px" variant="light-info">-->
            <!--                <span class="text-dark font-weight-700"> {{ data.item.role }} </span>-->
            <!--              </b-badge>-->
            <!--            </ul>-->
            <!--          </template>-->


            <!-- Delete User Button -->
            <template #cell(action)="data">
              <div>
                <b-button
                    v-if="data.item.state !=='accepted'"
                    class="btn-sm-block  text-primary"
                    variant="light"
                    @click="resendInvitation(data.item.id)"
                >
                  <feather-icon
                      class="mr-1"
                      icon="SendIcon"

                  />
                  <span class="align-middle">Send Again</span>
                </b-button>
                <!--                <b-button-->
                <!--                    v-if="data.item.state !=='accepted'"-->
                <!--                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                <!--                    variant="primary"-->
                <!--                    class="btn-icon mr-2"-->
                <!--                    v-b-tooltip.hover.top="'Resend'"-->
                <!--                    @click="resendInvitation(data.item.id)"-->

                <!--                >-->
                <!--                  <feather-icon icon="SendIcon" />-->
                <!--                </b-button>-->
                <!--                <b-button-->
                <!--                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                <!--                    variant="danger"-->
                <!--                    class="btn-icon"-->
                <!--                    @click="deleteInvitation(data.item.id)"-->
                <!--                    v-b-tooltip.hover.top="'Delete'"-->
                <!--                >-->
                <!--                  <feather-icon icon="TrashIcon" />-->
                <!--                </b-button>-->
              </div>

            </template>


          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <NoDataRow v-if="pagination.totalRows ===0" :messgae="'No Invitations to display'"/>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>

    </b-row>


  </div>

</template>

<script>

import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBModal,
  VBTooltip
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import vSelect from 'vue-select'
import NoDataRow from '@/views/staff-bank/Components/NoDataRow'
import settings from '@/apis/modules/settings'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import Filter from '@/mixins/FilterMixin'


export default {
  name: 'users-invitations',
  components: {
    vSelect,
    BInputGroupPrepend,
    BCard,
    BButton,
    BTabs,
    BTab,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
    NoDataRow

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data () {
    return {
      filters: {},
      tableLoading: false,
      modelShow: false,
      email: '',
      system_role_ids: '',
      job_role_ids: '',
      showSendInvitation: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'full_name',
          label: 'NAME'
        },
        {
          key: 'email',
          label: 'EMAIL'
        },
        {
          key: 'state',
          label: 'State'
        },
        {
          key: 'send_date',
          label: 'Date Invitation sent',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action'
        }

      ],
      items: [],
      jobRoleOption: [],
      systemRoleOption: []
    }
  },
  methods: {
    callparentfunction () {
      this.$parent.$parent.$parent.sendInvitation()

    },
    async systemRoleList () {
      try {
        const systemRoleResponse = await settings.SystemJobRoleList()
        this.systemRoleOption = systemRoleResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async jobRoleList () {
      try {
        const practiceListResponse = await settings.getJobRoleList()
        this.jobRoleOption = practiceListResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    resetData () {
      this.email = ''
      this.system_role_ids = ''
      this.job_role_ids = ''
    },
    async sendInvitationForm () {
      if (await this.$refs.InvitationSendForm.validate()) {
        try {
          this.modelShow = true
          const payload = {
            email: this.email,
            system_role_ids: [this.system_role_ids.id],
            job_role_id: this.job_role_ids.id
          }
          await settings.sendInvitation(payload)
          this.showSuccessMessage('Invitation Successfully')
          this.showSendInvitation = false
          this.modelShow = false
          this.resetData()
          await this.getInvitations()
          await this.systemRoleList()
        } catch (e) {
          this.convertAndNotifyError(e)
          this.modelShow = false
        }
      }
    },
    // async sendInvitation () {
    //   try {
    //     this.showSendInvitation = true
    //     await this.jobRoleList ()
    //     await this.systemRoleList()
    //   } catch (error) {
    //     this.convertAndNotifyError(error)
    //   }

    // },
    async resendInvitation (id) {
      try {
        this.tableLoading = true
        await settings.resendInvitation(id)
        this.showSuccessMessage('Resend Invitation Successfully')
        await this.getInvitations()
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    // async deleteInvitation (id) {
    //   try {
    //     this.tableLoading = true
    //     await settings.deleteInvitation( id)
    //     this.showSuccessMessage('Delete Invitation Successful')
    //     await this.getInvitations()
    //     this.tableLoading = false
    //   } catch (error) {
    //     this.convertAndNotifyError(error)
    //     this.tableLoading = false
    //
    //   }
    // },
    dataRefrrsh () {
      this.$refs.table.refresh()
    },

    async getInvitations () {
      try {
        this.tableLoading = true
        const Response = await settings.invitationList(this.filterQuery)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          email: x.email,
          // state: x.state,
          state: x.state.charAt(0).toUpperCase() + x.state.slice(1),
          send_date: this.momentFormat(x.created_at, 'ddd DD MM YYYY'),
          full_name: x.user ? this.getFullName(x.user) : 'Not registered',
          is_locum : x.is_locum,
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    filterQueryUpdate () {
      // this.getUsers()
      this.$refs.table.refresh()
    }
  },
  mounted () {

    // Set the initial number of items
    this.totalRows = this.items.length


  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
